import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import Heading from "../../../components/shared/heading";
import Text from "../../../components/shared/text";
import Link from "../../../components/shared/link";
import { SectionWrap, ContactInfoWrap, SingleInfo } from "./contact-info.stc";

const ContactInfo = ({ infoTextStyle, infoHeadingStyle, infoLinkStyle }) => {
    const siteQueryData = useStaticQuery(graphql`
        query contactSiteDataQuery {
            site {
                siteMetadata {
                    contact {
                        company_email
                    }
                }
            }
        }
    `);
    const { company_email } = siteQueryData.site.siteMetadata.contact;
    return (
        <SectionWrap>
            <div className="row justify-content-between align-items-center">
                <div className="col-4 offset-1">
                    <ContactInfoWrap>
                        <SectionTitle title="Contact Info." />
                        {company_email && (
                            <SingleInfo
                                className="wow fadeInLeft"
                                data-wow-delay="500ms"
                                data-wow-duration="1000ms"
                            >
                                <Text {...infoTextStyle}>Email</Text>
                                <Heading {...infoHeadingStyle}>
                                    <Link
                                        {...infoLinkStyle}
                                        path={`mailto:${company_email}`}
                                    >
                                        {company_email}
                                    </Link>
                                </Heading>
                            </SingleInfo>
                        )}
                    </ContactInfoWrap>
                </div>
            </div>
        </SectionWrap>
    );
};

ContactInfo.propTypes = {
    infoTextStyle: PropTypes.object,
    infoHeadingStyle: PropTypes.object
};

ContactInfo.defaultProps = {
    infoTextStyle: {
        fontSize: "16px",
        color: "primary",
        opacity: 0.8,
        fontweight: 400,
        texttransform: "uppercase",
        mb: "5px"
    },
    infoHeadingStyle: {
        as: "h6",
        fontSize: "16px",
        fontweight: 600,
        color: "primary",
        letterspacing: "0.5px",
        mb: 0,
        lineHeight: "22px"
    },
    infoLinkStyle: {
        color: "primary"
    }
};

export default ContactInfo;
