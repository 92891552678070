import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import ContactForm from "../../../components/contact-form";
import {
    ContactSectionWrap,
    ContactWrap,
    ContactFormWrap
} from "./contact-form.stc";

const ContactFormSection = ({ headingStyle, textStyle }) => {
    const contactData = useStaticQuery(graphql`
        query ContactBannerQuery {
            pagedataJson(id: { eq: "contact_page_data" }) {
                title
                subtitle
                banner_img {
                    childImageSharp {
                        fluid(maxWidth: 700, maxHeight: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
            site {
                siteMetadata {
                    form_url
                }
            }
        }
    `);
    const {
        siteMetadata: { form_url }
    } = contactData.site;
    return (
        <ContactSectionWrap>
            <div className="row align-items-center">
                <div className="col-4 offset-1">
                    <ContactWrap>
                        <SectionTitle title="Contact" />
                        <ContactFormWrap>
                            <ContactForm url={form_url} />
                        </ContactFormWrap>
                    </ContactWrap>
                </div>
            </div>
        </ContactSectionWrap>
    );
};

ContactFormSection.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
};

ContactFormSection.defaultProps = {
    headingStyle: {
        fontSize: "40px",
        lineHeight: "55px",
        color: "primary",
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: "24px",
                lineHeight: "initial"
            }
        }
    },
    textStyle: {
        as: "span",
        color: "primary",
        fontweight: 500,
        fontSize: "16px",
        texttransform: "uppercase",
        letterspacing: "1px",
        display: "inline-block",
        mb: "17px",
        lineHeight: 1,
        responsive: {
            medium: {
                fontSize: "16px",
                mb: "10px"
            }
        }
    }
};

export default ContactFormSection;
